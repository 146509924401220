import React, { Fragment, ReactNode, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Confetti from 'react-dom-confetti';


import './Wheel.css';


declare const dsLib: any;



function Segment(seg_number: number, seg_color: string) {
    return { "--i": seg_number, "--clr": seg_color } as React.CSSProperties;
}

function SpinWheel({ setWinningSegmentText, setImageSource, setConfetti  }: { setWinningSegmentText: (text: string) => void, setImageSource: (text: string) => void,setConfetti: (isActive: boolean) => void }) {

    let wheel = document.querySelector('.wheel') as HTMLDivElement;
    const segments = document.querySelectorAll('.segment');
    const numSegments = segments.length;

    let endAngle = Math.ceil(Math.random() * 3600);
    wheel.style.transform = `rotate(${-endAngle}deg)`;

    const spinBtn = document.querySelector('.spinBtn');
        if (spinBtn)
            spinBtn.classList.add('rotating');

    setTimeout(() => {
        endAngle %= 360;

        const segmentAngle = 360 / numSegments;

        let segmentIndex = 0;

        for (let i = 0; i < numSegments; i++) {
            const segmentStartAngle = i * segmentAngle;
            const segmentEndAngle = (i + 1) * segmentAngle;
            if (endAngle >= segmentStartAngle && endAngle < segmentEndAngle) {
                segmentIndex = i;
            }
        }

        const segment = segments[segmentIndex] as HTMLDivElement;
        const segmentText = segment.textContent?.trim();
        const segmentImageSource = segment.querySelector('img')

        setWinningSegmentText(segmentText || '');
        setImageSource(segmentImageSource?.src || '')

        new dsLib.Alert('alert_info').show();
        setConfetti(true); 
        if (spinBtn)
            spinBtn.classList.remove('rotating');
    }, 6000);
}

function WinAlert({ winningSegmentText, imageSource }: { winningSegmentText: string, imageSource: string }): ReactNode {
    return (
        <button style={{border: 'none', background: 'none'}}> 
        Vous avez gagné : {winningSegmentText} <img src={imageSource} alt={winningSegmentText} draggable="false" />
        </button>
    );
}


export const Wheel = () => {    
    const [winningSegmentText, setWinningSegmentText] = useState('');
    const [imageSource, setImageSource] = useState('');
    const [confetti, setConfetti] = useState(false);
    const [wheelHasSpun, setWheelHasSpun] = useState(false);

    const leftConfig = {
        angle: 90,
        spread: 360,
        startVelocity: 20,
        elementCount: 200,
        dragFriction: 0.12,
        duration: 3000,
        delay: 0,
        width: "10px",
        height: "10px",
        colors: ["#a864fd", "#29cdff", "#20e39e", "#e0f1fc", "#b5ffb5"]
      };
      const rightConfig = {
        angle: 270,
        spread: 360,
        startVelocity: 20,
        elementCount: 200,
        dragFriction: 0.12,
        duration: 3000,
        delay: 0,
        width: "10px",
        height: "10px",
        colors: ["#a864fd", "#29cdff", "#20e39e", "#e0f1fc", "#b5ffb5"]
      };

    const handleSpinClick = () => {
        if (!wheelHasSpun) {
            SpinWheel({setWinningSegmentText, setImageSource, setConfetti});

            // Supprimer le flou des éléments spécifiques
            const elementsToUnblur = document.querySelectorAll('.header, .container, .footer');
            elementsToUnblur.forEach(el => {
                el.classList.remove('blur');  // Assurez-vous que cette ligne est exécutée
                el.classList.add('no-blur');  // et que cette ligne est également exécutée
            });

            setWheelHasSpun(true);
        }
    };

    return ( 
        <Fragment>
            {localStorage.getItem('answeredCorrectly') === 'true' ? null : <Navigate to ='/'/>}
            <header className='header'>
                <h1 className="pink-border blur">LA GRANDE ROUE (BINGO)</h1>
                {/* <div className="square-container blur">
               HIT THE BUTTON
                </div> */}
            
            </header>
            {/* Conffetti droite et gauche */}

            <div className="confetti-left">
                <Confetti active={confetti} config={leftConfig} />
            </div>
            <div className="confetti-right">
                <Confetti active={confetti} config={rightConfig} />
            </div>

            {/* <main> */}
                                 
                <div className='container'>
                    <button className="spinBtn no-blur" onClick={handleSpinClick}>
                    </button>
                    <div className="arrow blur"></div>
                    <div className='wheel blur'>                    
                        <div className='segment' style={Segment(1, '#e84447')}>
                            <img src='tshirt.png' alt='Exemple 1' draggable='false' />
                            TShirt
                        </div>
                        <div className='segment' style={Segment(2, '#fdc800')}>
                            <img src='bonbon.png' alt='Exemple 2' draggable='false'/>
                            Bonbons
                        </div>
                        <div className='segment' style={Segment(3, '#ec6b1f')}>
                            <img src='stickers.png' alt='Exemple 3' draggable='false' style={{ maxWidth: '50%', maxHeight: '50%' }}/>
                            Stickers
                        </div>
                        <div className='segment' style={Segment(4, '#e61f67')}>
                            <img src='bonbon.png' alt='Exemple 4' draggable='false' />
                            Bonbons
                        </div>
                        <div className='segment' style={Segment(5, '#e84447')}>
                            <img src='affiche.png' alt='Exemple 5' draggable='false' style={{ maxWidth: '50%', maxHeight: '50%' }} />
                            Carte à planter
                        </div>
                        <div className='segment' style={Segment(6, '#fdc800')}>
                            <img src='bonbon.png' alt='Exemple 6' draggable='false' />
                            Bonbons
                        </div>
                        <div className='segment' style={Segment(7, '#ec6b1f')}>
                            <img src='stickers.png' alt='Exemple 7' draggable='false' style={{ maxWidth: '50%', maxHeight: '50%' }}/>
                            Stickers
                        </div>
                        <div className='segment' style={Segment(8, "#e61f67")}>
                            <img src="affiche.png" alt="Exemple 8" draggable="false" style={{ maxWidth: '50%', maxHeight: '50%' }} />
                            Carte à planter
                        </div>
                    </div>
                    <section>
                        <div id="alert_info" className="ds-alert ds-alert--info wheel-notif" aria-label="Félicitations !" role="alert" tabIndex={0}>
                        <div className="ds-alert-group">
                        <div className="ds-alert-group__header">
                            <h6 className='Congrats'>Félicitations !</h6>
                            <button id="close_alert_info">
                                <span className="ds-icon" aria-hidden="true">close</span>
                            </button>
                        </div>
                        <div className="ds-alert-group__body">
                            <p className="ds-body-2--regular">{WinAlert({ winningSegmentText, imageSource })}</p>
                            <div className="social-icons">
                                <a href="https://twitter.com/bpifrance_io" className="twitter-icon">
                                    <FontAwesomeIcon icon={faTwitter} style={{color: "#1DA1F2"}} />
                                </a>
                                <a href="https://www.linkedin.com/company/bpifrance-io/" className="linkedin-icon">
                                    <FontAwesomeIcon icon={faLinkedin} style={{color: "#0077b5"}} />
                                </a>
                                <a href="https://talents.bpifrance.fr/nos-opportunites/?equipe=digital-it&criteres=digital-it">Nos offres</a>
                            </div>
                        </div>
                        </div>
                        </div>
                    </section>
                </div>
                { <div className='spin-button'>
                </div> }
                <footer className='footer blur'>
                    <img src="logo_io.png" alt="Logo" className='bpifrance-logo' style={{ width: '150px', height: 'auto' }} />
                    {/* <p>Made by Max Gay & Thibault Babeau</p> */}
                </footer>            
        </Fragment>
    );
}
